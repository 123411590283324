<template>
    <CriarUsuario />
</template>

<script>
import CriarUsuario from '@/components/Usuario/CriarUsuario'
export default {
  components: {CriarUsuario},
  data(){
    return{}
  }
}
</script>

<style>

</style>